<script>
  /**
   * An image slider with navigation and scrollbar pagination.
   */
  export default {
    props: {
      /** Images used in the slider. */
      images: {
        type: Array,
        required: true,
      },
      /** The images' alternative text. */
      alt: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        imageActiveIndex: 0,
      }
    },
  }
</script>

<template>
  <div>
    <BaseSlider
      has-navigation
      has-scrollbar
      :active-index="imageActiveIndex"
      class="slider"
      @slideChange="imageActiveIndex = $event"
    >
      <BaseSliderSlide v-for="(imageUrl, imageIndex) in images" :key="imageIndex">
        <BaseImage :src="imageUrl" :alt="alt" responsive="lg" :sizes="{ md: '80vw', lg: '40vw' }" />
      </BaseSliderSlide>
    </BaseSlider>
  </div>
</template>

<style lang="postcss" scoped>
  /* Override BaseSlider's default CSS */
  .slider {
    & >>> {
      .swiper-button-prev,
      .swiper-button-next {
        @apply border-1 border-black -mt-5 bg-dawn;

        @screen md {
          @apply -mt-7;
        }
      }

      .swiper-button-prev {
        @apply left-0 ml-1;
      }

      .swiper-button-next {
        @apply right-0 mr-1;
      }

      .swiper-slide:not(:only-of-type) {
        @apply -ml-2;
      }
    }
  }
</style>
